// External Dependencies
import { combineReducers } from 'redux';
import { createReducer } from 'deox';
import cloneDeep from 'lodash.clonedeep';

// Internal Dependencies
import { LocalStorageItem, getLocalStorageItem } from 'utils/lib/local_storage';
import { getEndingSchoolYear } from 'utils';
import { logoutCurrentUser } from 'state/self/actions';

// Local Dependencies
import {
  EditModeTable,
  TableResource,
  TableSelection,
  updateEditModeTable,
  updateIsPaginatedListDataLoaded,
  updateTableQueryParams,
  updateTableSelections,
} from './actions';

// Local Typings
interface Action<T> {
  payload: Partial<T>;
  type: string;
}

export interface TableState {
  editModeTable: EditModeTable | null;
  isPaginatedListDataLoaded: boolean;
  tableParams: Record<TableResource, string | undefined>;
  tableSelections: Record<TableResource, TableSelection | undefined>;
}

// Local Variables
const initialSelection: TableSelection = {
  ids: [],
  selectionType: 'SelectedOne',
};

const getInitialPageSize = (localStorageItem: LocalStorageItem) => {
  const pageSize = getLocalStorageItem(localStorageItem);

  return pageSize ? parseInt(pageSize, 10) : 10;
};

const paymentsDefaultFilter = `${encodeURI('dataGridFilters={"items":[{"field":"isMemberActive","operator":"is","id":45403,"value":"true"}],"logicOperator":"and"}')}`;
const peopleDefaultFilter = `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'lastName', sort: 'asc' }]))}`;
const libraryDefaultFilter = `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'title', sort: 'asc' }]))}`;

const feesFilterObject = {
  items: [
    {
      field: 'isActiveMember',
      id: 53238,
      operator: 'is',
      value: 'true',
    },
    {
      field: 'isRemoved',
      id: 53239,
      operator: 'is',
      value: 'false',
    },
  ],
  logicOperator: 'and',
  quickFilterLogicOperator: 'and',
  quickFilterValues: [
    '',
  ],
};

const defaultFeesFilter = `${encodeURI(`dataGridFilters=${JSON.stringify(feesFilterObject)}`)}`;

// The Licenses table should default to showing:
// - Active licenses
// - Unredeemed licenses
const licensesDefaultFilter = `?${encodeURI('dataGridFilters={"items":[{"field":"isActive","operator":"is","id":19199,"value":"true"},{"field":"redeemedAt","operator":"isEmpty","id":6090,"value":"true"}],"logicOperator":"or","quickFilterValues":[""],"quickFilterLogicOperator":"and"}')}`;

const pendingMembersDefaultParams = `?dataGridFilters=${encodeURIComponent('{"items":[],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}')}`;

const districtEmailFilters = '{"items":[{"field":"status","operator":"is","id":28349,"value":"sent"},{"field":"senderType","operator":"is","id":33013,"value":"director"}],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}';
const districtEmailSort = '[{"field":"sentAt","sort":"desc"}]';

// Reducers
const schoolYearEnding = getEndingSchoolYear();

const TABLE_PARAMS_INITIAL_STATE: TableState = {
  editModeTable: null,
  isPaginatedListDataLoaded: false,
  tableParams: {
    checklist: '',
    checklists: '',
    directorPermissions: peopleDefaultFilter,
    directors: peopleDefaultFilter,
    districtAssistants: '?asc=true&orderBy=lastName',
    districtDeletedInventoryItems: `?asc=true&orderBy=label&limit=${getInitialPageSize('districtDeletedInventoryItemsPageSize')}&page=1`,
    districtEmails: `?dataGridFilters=${encodeURIComponent(districtEmailFilters)}&dataGridSort=${encodeURIComponent(districtEmailSort)}`,
    districtForms: '',
    districtInventoryItems: `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'label', sort: 'asc' }]))}`,
    districtLibraryItems: libraryDefaultFilter,
    districtLicenses: '',
    districtOrganizations: `?dataGridFilters=${decodeURIComponent('{"items":[],"logicOperator":"and","quickFilterValues":[""],"quickFilterLogicOperator":"and"}&dataGridSort=[{"field":"label","sort":"asc"}]')}`,
    districtOrganizationsDirectors: `?asc=true&orderBy=lastName&limit=${getInitialPageSize('districtOrganizationsDirectorsPageSize')}&page=1`,
    districtOrganizationsParentsWithPermissions: `?asc=true&orderBy=lastName&limit=${getInitialPageSize('districtOrganizationsParentsWithPermissionsPageSize')}&page=1`,
    districtUniforms: `?asc=true&orderBy=number&limit=${getInitialPageSize('districtUniformsPageSize')}&page=1`,
    dynamicFields: '',
    emailDrafts: '',
    emails: `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'sentAt', sort: 'desc' }]))}`,
    fileDirectories: `?asc=true&orderBy=directory&limit=${getInitialPageSize('fileDirectoriesPageSize')}&page=1`,
    files: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('filesPageSize')}&page=1`,
    financialCredits: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('financialCreditsPageSize')}&page=1`,
    financialDeposits: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('financialDepositsPageSize')}&page=1`,
    [`financialFees-${schoolYearEnding - 1}`]: `?asc=true&orderBy=userLastName&${defaultFeesFilter}&limit=${getInitialPageSize(`financialFees-${schoolYearEnding - 1}PageSize`)}&page=1`,
    [`financialFees-${schoolYearEnding}`]: `?asc=true&orderBy=userLastName&${defaultFeesFilter}&limit=${getInitialPageSize(`financialFees-${schoolYearEnding}PageSize`)}&page=1`,
    [`financialFees-${schoolYearEnding + 1}`]: `?asc=true&orderBy=userLastName&${defaultFeesFilter}&limit=${getInitialPageSize(`financialFees-${schoolYearEnding + 1}PageSize`)}&page=1`,
    financialFundraiserCredits: '',
    financialItemMemberFees: '?dataGridFilters=%7B"items"%3A%5B%7B"field"%3A"roleLabel"%2C"operator"%3A"is"%2C"id"%3A16130%2C"value"%3A"Student"%7D%5D%2C"logicOperator"%3A"and"%2C"quickFilterValues"%3A%5B""%5D%2C"quickFilterLogicOperator"%3A"and"%7D',
    [`financialItems-${schoolYearEnding - 1}`]: `?asc=true&orderBy=label&limit=${getInitialPageSize(`financialItems-${schoolYearEnding - 1}PageSize`)}&page=1`,
    [`financialItems-${schoolYearEnding}`]: `?asc=true&orderBy=label&limit=${getInitialPageSize(`financialItems-${schoolYearEnding}PageSize`)}&page=1`,
    [`financialItems-${schoolYearEnding + 1}`]: `?asc=true&orderBy=label&limit=${getInitialPageSize(`financialItems-${schoolYearEnding + 1}PageSize`)}&page=1`,
    [`financialPayments-${schoolYearEnding - 1}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding - 1}PageSize`)}&page=1&${paymentsDefaultFilter}`,
    [`financialPayments-${schoolYearEnding}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding}PageSize`)}&page=1&${paymentsDefaultFilter}`,
    [`financialPayments-${schoolYearEnding + 1}`]: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding + 1}PageSize`)}&page=1&${paymentsDefaultFilter}`,
    financialPaymentsOnTransaction: '',
    financialTransactions: '',
    forms: '',
    group: `?asc=true&orderBy=last_name&limit=${getInitialPageSize('groupPageSize')}&page=1`,
    groups: `?asc=true&orderBy=label&limit=${getInitialPageSize('groupsPageSize')}&page=1`,
    inactiveUsers: peopleDefaultFilter,
    inventoryAudits: `?asc=true&orderBy=label&limit=${getInitialPageSize('inventoryAuditsPageSize')}&page=1`,
    inventoryItems: `?asc=true&orderBy=label&limit=${getInitialPageSize('inventoryItemsPageSize')}&page=1`,
    libraryItems: libraryDefaultFilter,
    licenses: licensesDefaultFilter,
    myFiles: `?asc=true&orderBy=fileName&limit=${getInitialPageSize('myFilesPageSize')}&page=1`,
    myFinancialCredits: `?asc=false&orderBy=createdAt&limit=${getInitialPageSize('myFinancialCreditsPageSize')}&page=1`,
    myFinancialFees: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize('myFinancialFeesPageSize')}&page=1`,
    myFinancialPayments: `?asc=true&orderBy=userLastName&limit=${getInitialPageSize(`financialPayments-${schoolYearEnding}PageSize`)}&page=1`,
    myForms: '',
    myGroups: '',
    parents: peopleDefaultFilter,
    paymentPickerDepositForm: '?isDeposited=false',
    pendingMembers: pendingMembersDefaultParams,
    peoplePickerChecklistMembers: peopleDefaultFilter,
    peoplePickerEmail: peopleDefaultFilter,
    peoplePickerEndOfYearNonReturningStudents: peopleDefaultFilter,
    peoplePickerFeeForm: peopleDefaultFilter,
    peoplePickerForm: peopleDefaultFilter,
    peoplePickerGroups: peopleDefaultFilter,
    peoplePickerSelectOneUser: peopleDefaultFilter,
    peoplePickerStatementCard: peopleDefaultFilter,
    previousMembers: '',
    returningStudents: '',
    scheduledSendEmails: '',
    students: peopleDefaultFilter,
    suppressedEmails: peopleDefaultFilter,
    uniforms: `?dataGridSort=${encodeURIComponent(JSON.stringify([{ field: 'type', sort: 'asc' }, { field: 'size', sort: 'desc' }]))}`,
  },
  tableSelections: {
    checklist: cloneDeep(initialSelection),
    checklists: cloneDeep(initialSelection),
    directorPermissions: cloneDeep(initialSelection),
    directors: cloneDeep(initialSelection),
    districtAssistants: cloneDeep(initialSelection),
    districtDeletedInventoryItems: cloneDeep(initialSelection),
    districtEmails: cloneDeep(initialSelection),
    districtForms: cloneDeep(initialSelection),
    districtInventoryItems: cloneDeep(initialSelection),
    districtLibraryItems: cloneDeep(initialSelection),
    districtLicenses: cloneDeep(initialSelection),
    districtOrganizations: cloneDeep(initialSelection),
    districtOrganizationsDirectors: cloneDeep(initialSelection),
    districtOrganizationsParentsWithPermissions: cloneDeep(initialSelection),
    districtUniforms: cloneDeep(initialSelection),
    dynamicFields: cloneDeep(initialSelection),
    emailDrafts: cloneDeep(initialSelection),
    emails: cloneDeep(initialSelection),
    fileDirectories: cloneDeep(initialSelection),
    files: cloneDeep(initialSelection),
    financialCredits: cloneDeep(initialSelection),
    financialDeposits: cloneDeep(initialSelection),
    [`financialFees-${schoolYearEnding - 1}`]: cloneDeep(initialSelection),
    [`financialFees-${schoolYearEnding}`]: cloneDeep(initialSelection),
    [`financialFees-${schoolYearEnding + 1}`]: cloneDeep(initialSelection),
    financialFundraiserCredits: cloneDeep(initialSelection),
    financialItemMemberFees: cloneDeep(initialSelection),
    [`financialItems-${schoolYearEnding - 1}`]: cloneDeep(initialSelection),
    [`financialItems-${schoolYearEnding}`]: cloneDeep(initialSelection),
    [`financialItems-${schoolYearEnding + 1}`]: cloneDeep(initialSelection),
    [`financialPayments-${schoolYearEnding - 1}`]: cloneDeep(initialSelection),
    [`financialPayments-${schoolYearEnding}`]: cloneDeep(initialSelection),
    [`financialPayments-${schoolYearEnding + 1}`]: cloneDeep(initialSelection),
    financialPaymentsOnTransaction: cloneDeep(initialSelection),
    financialTransactions: cloneDeep(initialSelection),
    forms: cloneDeep(initialSelection),
    group: cloneDeep(initialSelection),
    groups: cloneDeep(initialSelection),
    inactiveUsers: cloneDeep(initialSelection),
    inventoryAudits: cloneDeep(initialSelection),
    inventoryItems: cloneDeep(initialSelection),
    libraryItems: cloneDeep(initialSelection),
    licenses: cloneDeep(initialSelection),
    myFiles: cloneDeep(initialSelection),
    myFinancialCredits: cloneDeep(initialSelection),
    myFinancialFees: cloneDeep(initialSelection),
    myFinancialPayments: cloneDeep(initialSelection),
    myForms: cloneDeep(initialSelection),
    myGroups: cloneDeep(initialSelection),
    parents: cloneDeep(initialSelection),
    paymentPickerDepositForm: cloneDeep(initialSelection),
    pendingMembers: cloneDeep(initialSelection),
    peoplePickerChecklistMembers: cloneDeep(initialSelection),
    peoplePickerEmail: cloneDeep(initialSelection),
    peoplePickerEndOfYearNonReturningStudents: cloneDeep(initialSelection),
    peoplePickerFeeForm: cloneDeep(initialSelection),
    peoplePickerForm: cloneDeep(initialSelection),
    peoplePickerGroups: cloneDeep(initialSelection),
    peoplePickerSelectOneUser: cloneDeep(initialSelection),
    peoplePickerStatementCard: cloneDeep(initialSelection),
    previousMembers: cloneDeep(initialSelection),
    returningStudents: cloneDeep(initialSelection),
    scheduledSendEmails: cloneDeep(initialSelection),
    students: cloneDeep(initialSelection),
    suppressedEmails: cloneDeep(initialSelection),
    uniforms: cloneDeep(initialSelection),
  },
};

const editModeTable = createReducer(
  TABLE_PARAMS_INITIAL_STATE.editModeTable,
  (handleAction) => [
    handleAction(updateEditModeTable, (_state, { payload }: Action<TableState['editModeTable']>) => payload),
    handleAction(logoutCurrentUser, () => TABLE_PARAMS_INITIAL_STATE.editModeTable),
  ],
);

const isPaginatedListDataLoaded = createReducer(
  TABLE_PARAMS_INITIAL_STATE.isPaginatedListDataLoaded,
  (handleAction) => [
    handleAction(updateIsPaginatedListDataLoaded, (_state, { payload }: Action<TableState['isPaginatedListDataLoaded']>) =>
      payload),
  ],
);
const tableParams = createReducer(
  TABLE_PARAMS_INITIAL_STATE.tableParams,
  (handleAction) => [
    handleAction(updateTableQueryParams, (state, { payload }: Action<TableState['tableParams']>) => ({
      ...state,
      ...payload,
    })),
  ],
);
const tableSelections = createReducer(
  TABLE_PARAMS_INITIAL_STATE.tableSelections,
  (handleAction) => [
    handleAction(updateTableSelections, (state, { payload }: Action<TableState['tableSelections']>) => ({
      ...state,
      ...payload,
    })),
  ],
);

export default combineReducers<TableState>({
  editModeTable,
  isPaginatedListDataLoaded,
  tableParams,
  tableSelections,
});
