export const ROLES = {
  ADULT: 'ADULT',
  STUDENT: 'STUDENT',
};

export const ROLE_IDS = {
  [ROLES.ADULT]: '2',
  [ROLES.STUDENT]: '1',
};

const BILLING = 'billing@presto-assistant.com';
const SUPPORT = 'support@presto-assistant.com';
export const CONTACT_INFO = {
  MAILTO_BILLING: `mailto:${BILLING}`,
  MAILTO_SUPPORT: `mailto:${SUPPORT}`,
  SUPPORT,
};

export const APP_NAME = 'Presto Assistant';
export const SHORT_APP_NAME = 'Presto';
export const FORM_W9_HREF = 'https://www.presto-assistant.com/downloads/presto-assistant-form-w9.pdf';
export const PRIVACY_POLICY_HREF = 'https://www.presto-assistant.com/privacy_policy';
export const NEW_RELEASES_HREF = 'https://www.presto-assistant.com/releases';
export const SUPPORT_SITE_HREF = 'https://support.presto-assistant.com';
export const TERMS_OF_SERVICE_HREF = 'https://www.presto-assistant.com/terms_of_service';
export const TOKEN_KEY = 'token';
export const END_OF_YEAR_PROCESS_DEADLINE = 'June 30th';
export const EMPTY_UUID = '00000000-0000-0000-0000-000000000000';
export const IS_PRODUCTION = process.env.REACT_APP_APPLICATION_ENVIRONMENT === 'production';
export const IS_STAGING = process.env.REACT_APP_APPLICATION_ENVIRONMENT === 'staging';

// TODO: remove this when the Financial Deposits code is fully ready to ship
export const showFinancialDeposits = false;
