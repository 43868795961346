// External Dependencies
import {
  CreateDistrictInventoryAuditRequest,
} from '@presto-assistant/api_types/api/v1/districtInventoryAudit';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';

// Internal Dependencies
import { apiClient } from 'utils/apiClient';
import { evictApolloCache } from 'gql/client';
import { useTanstackMutation } from 'utils/api/index';

export const useGetAllDirectors = () => {
  const queryFn = useCallback(() => {
    return apiClient.v1.district.organizationDirectors();
  }, []);

  return useQuery({
    queryFn,
  });
};

export const useGetDistrictEntitiesByAdminId = () => {
  const queryFn = useCallback(() => {
    return apiClient.v1.district.entities();
  }, []);

  return useQuery({
    queryFn,
    queryKey: ['entities'],
  });
};

export const useGetDistrictInventoryAudits = () => {
  const queryFn = useCallback(() => {
    return apiClient.v1.districtInventoryAuditClient.listDistrictInventoryAudits();
  }, []);

  return useQuery({
    queryFn,
    queryKey: ['inventoryAudits'],
  });
};

export const useCreateDistrictInventoryAudit = () => {
  const queryClient = useQueryClient();

  const mutationFn = useCallback((request: Pick<CreateDistrictInventoryAuditRequest, 'body'>) => {
    return apiClient.v1.districtInventoryAuditClient.createDistrictInventoryAudit(request);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      queryClient.invalidateQueries(['inventoryAudits']);
    },
  });
};

export const useGetDistrictInventoryAudit = (id: string) => {
  return useQuery({
    queryFn: () => {
      return apiClient.v1.districtInventoryAuditClient.getDistrictInventoryAudit({
        params: { id },
      });
    },
    queryKey: ['inventoryAudit', id],
  });
};

export const useCreateTaxExemptFormPresignedUrl = () => {
  const mutationFn = useCallback((payload) => {
    return apiClient.v1.district.createTaxExemptFormPresignedUrl(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: (): void => {
      evictApolloCache('district');
    },
  });
};

export const useUpdateTaxExemptFormS3Key = () => {
  const navigate = useNavigate();

  const mutationFn = useCallback((payload) => {
    return apiClient.v1.district.updateTaxExemptFormS3Key(payload);
  }, []);

  return useTanstackMutation({
    mutationFn,
    onSuccess: () => {
      evictApolloCache('districtById');
      evictApolloCache('districts');
      evictApolloCache('district');
      navigate(-1);
    },
  });
};

export const useDownloadTaxExemptForm = () => {
  return useTanstackMutation({
    mutationFn: () =>
      apiClient.v1.district.downloadTaxExemptForm(),
  });
};
