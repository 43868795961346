// External Dependencies
import {
  FC, useCallback, useEffect, useState,
} from 'react';
import { FeatureFlag } from '@presto-assistant/api_types';
import { useSelector } from 'react-redux';
import DisableIcon from '@mui/icons-material/RemoveCircleOutline';
import EnableIcon from '@mui/icons-material/Add';

// Internal Dependencies
import { ConfirmationDialog, ListItemWithSecondaryAction, StyledLink } from 'components/shared';
import { hasFeatureFlag } from 'state/self/selectors';
import { stripeUrl, vancoUrl } from 'utils/constants/urls';
import { useGetDistrict } from 'gql/queries';
import { useIsOpen } from 'hooks/useIsOpen';
import { useUpdateDistrictOnlinePaymentSettings } from 'gql/mutations';

// Component Definition
const OnlinePaymentsIntegrationSetting: FC = () => {
  const [shouldPresentEnabledInDialog, setShouldPresentEnabledInDialog] = useState(false);

  const hasOnlinePaymentsFeatureFlag = useSelector(hasFeatureFlag(FeatureFlag.OnlinePayments));

  const {
    data: districtData,
    loading: isDistrictLoading,
  } = useGetDistrict();

  const {
    isOpen: isDialogOpen,
    toggleIsOpen: toggleIsDialogOpen,
  } = useIsOpen();

  const areOnlinePaymentsEnabled = districtData?.district.areOnlinePaymentsEnabled;

  const [
    updateDistrictSettings,
    {
      loading: isSubmitting,
    },
  ] = useUpdateDistrictOnlinePaymentSettings({
    onCompleted: toggleIsDialogOpen,
  });

  const handleSubmit = useCallback(() => {
    updateDistrictSettings({
      variables: {
        input: {
          areOnlinePaymentsEnabled: !areOnlinePaymentsEnabled,
        },
      },
    });
  }, [areOnlinePaymentsEnabled, updateDistrictSettings]);

  useEffect(() => {
    if (isDialogOpen) {
      setShouldPresentEnabledInDialog(Boolean(areOnlinePaymentsEnabled));
    }
    // intentionally leaving out areOnlinePaymentsEnabled from this dependency array
    // this allows the dialog content to persist while closing
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  if (isDistrictLoading || !hasOnlinePaymentsFeatureFlag) {
    return null;
  }

  return (
    <>
      <ListItemWithSecondaryAction
        primaryText={<>Online Payments: <strong>{areOnlinePaymentsEnabled ? 'ON' : 'OFF'}</strong></>}
        secondaryAction={{
          alwaysShowButton: true,
          buttonIcon: areOnlinePaymentsEnabled ? <DisableIcon /> : <EnableIcon />,
          buttonText: areOnlinePaymentsEnabled ? 'Disable' : 'Enable',
          isLoading: isSubmitting,
          onClick: toggleIsDialogOpen,
        }}
        secondaryText={(
          <>
            Allow organizations in your district to enable online payments using
            {' '}
            <StyledLink
              href={stripeUrl}
              openInNewTab
            >
              Stripe
            </StyledLink>{' '}
            or{' '}
            <StyledLink
              href={vancoUrl}
              openInNewTab
            >
              Vanco
            </StyledLink>
            .
          </>
        )}
      />

      <ConfirmationDialog
        confirmButtonAction={handleSubmit}
        confirmButtonText={shouldPresentEnabledInDialog ? 'Yes, disable' : 'Yes, enable'}
        declineButtonAction={toggleIsDialogOpen}
        description={shouldPresentEnabledInDialog
          ? 'This will prevent organizations in your district from accepting online payments.'
          : 'This will allow organizations in your district to enable online payments for their program.'}
        handleClose={toggleIsDialogOpen}
        isSubmitting={isSubmitting}
        open={isDialogOpen}
        title={shouldPresentEnabledInDialog ? 'Disable online payments?' : 'Enable online payments?'}
      />
    </>
  );
};

export default OnlinePaymentsIntegrationSetting;
